@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .flex-col {
    flex-direction: column;
  }

  .border-b {
    border-bottom: 1px solid #ccc;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .generate-draft-button {
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 12px 16px;
    margin-top: 16px;
    /* Add any other mobile-specific styles */
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; /* Adjust this value as needed */
  }
  
  .draft-dialog-step2 {
    flex-direction: column;
  }

  .draft-dialog-step2 .chatbot-container {
    height: 70vh;
    margin-bottom: 1rem;
  }

  .draft-dialog-step2 .anecdote-container {
    height: 20vh;
  }

  .draft-dialog-step2 .anecdote-content {
    max-height: 120px;
  }

  
}

@media (max-width: 548px) {
  .generate-draft-button {
    position: relative;
    right: 20%

  }
}

/* For larger screens */
@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:px-48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    border: 4px solid rgba(0, 0, 0, 0);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 100vh;
    background-color: rgb(226, 232, 240);
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgb(203, 213, 225);
  }
}

.draft-dialog-step4 {
  height: calc(100vh - 200px);
}

@media (max-width: 767px) {
  .draft-dialog-step4 {
    height: calc(100vh - 150px);
  }
}

.custom-select-item {
  background-color: transparent !important;
  color: inherit !important;
}

.custom-select-item:hover {
  background-color: hsl(var(--accent)) !important;
  color: hsl(var(--accent-foreground)) !important;
}